import React, { useCallback } from 'react';

import cx from 'classnames';

import { copyToClipboard } from 'utils/main';

import { FileCopyIcon, IconProps } from '../Icons__deprecated';
import { useText } from '../Language';
import { Text } from '../Text';
import { useToasts } from '../Toast';

import css from './SocialLink.module.css';

interface SocialLinkProps {
  Icon: React.FC<IconProps>;
  link?: string;
  value: string;
  withCopyIcon?: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
}
export const SocialLink = React.memo<SocialLinkProps>(
  ({
    value,
    Icon,
    link,
    withCopyIcon = true,
    className,
    iconClassName,
    onClick = () => undefined,
  }) => {
    const text = useText(state => state.userGames.card.toastLinkCopied);
    const { alert } = useToasts();

    const handleCopy = useCallback(() => {
      copyToClipboard(value);
      alert({ type: 'info', message: text });
    }, [value]);

    return (
      <section className={cx(css.container, { [css.linkContainter]: withCopyIcon })}>
        <a
          className={css.linkWrapper}
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          onClick={event => {
            if (!link) {
              event.preventDefault();
              handleCopy();
              onClick(event);
            }
          }}
        >
          <Icon className={cx(css.icon, iconClassName)} />
          <Text.Regular className={cx(css.label, className)} size={4}>
            {value}
          </Text.Regular>
        </a>
        {withCopyIcon ? (
          <div className={css.copyWrapper} onClick={handleCopy}>
            <FileCopyIcon />
          </div>
        ) : null}
      </section>
    );
  },
);

export const SocialLinkIcon = React.memo<SocialLinkProps>(
  ({ value, Icon, link, className, iconClassName, onClick = () => undefined }) => {
    const text = useText(state => state.userGames.card.toastLinkCopied);
    const { alert } = useToasts();

    const handleCopy = useCallback(() => {
      copyToClipboard(value);
      alert({ type: 'info', message: text });
    }, [value]);

    return (
      <a
        className={cx(css.linkIconWrapper, className)}
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        onClick={event => {
          onClick(event);

          if (!link) {
            event.preventDefault();
            handleCopy();
          }
        }}
      >
        <Icon className={cx(css.linkIcon, iconClassName)} />
      </a>
    );
  },
);
