import React from 'react';

import { NextPage } from 'next';

import { api, getToken, useGetMeState } from 'api';

import { useApp } from 'components/ContextApp';
import { MobileProfile } from 'components/MobileProfile';

import { useConsoleCommand } from 'utils/main';

import { Desktop } from './Desktop';

export interface PageProfileProps {
  hasRenderedOnServer?: boolean;
  user: api.Maybe<api.User>;
  loading: boolean;
  gameId: string;
}

export interface SharedProps {
  loading: boolean;
  me: api.Maybe<api.User>;
  user: api.Maybe<api.User>;
  game: api.Maybe<api.Game>;
  hasRenderedOnServer: boolean;
}

export const PageUserGames: NextPage<PageProfileProps> = ({
  hasRenderedOnServer = false,
  loading,
  gameId,
  user,
}) => {
  const app = useApp();
  const { me } = useGetMeState();

  const gameResult = api.useGetGamePreviewByIdQuery({
    variables: { gameId },
    skip: !gameId,
  });

  const game = gameResult.data?.getGamePreviewById.game as api.Game;

  // Bans user if you are a god
  useConsoleCommand(
    'banUser',
    async () => {
      if (!user) {
        return 'No user to ban';
      }

      return fetch(`https://api.lf.group/admin/api/users/${user.id}/ban`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: { 'x-token': getToken() || '' },
      });
    },
    [user],
  );

  // Deletes user if you are a god
  useConsoleCommand(
    'deleteUser',
    async () => {
      if (!user) {
        return 'No user to delete';
      }

      return fetch(`https://api.lf.group/admin/api/users/${user.id}`, {
        method: 'DELETE',
        // mode: 'no-cors',
        headers: { 'x-token': getToken() || '' },
      });
    },
    [user],
  );

  if (app.isMobile) {
    return <MobileProfile loading={loading} me={me} user={user} />;
  }

  return (
    <Desktop
      game={game}
      hasRenderedOnServer={hasRenderedOnServer}
      loading={loading}
      me={me}
      user={user}
    />
  );
};
