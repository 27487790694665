import React from 'react';

import { Button } from 'components/Button';
import { Text } from 'components/Text';

import { useText } from 'text';

import css from './EmptyProfile.module.css';

interface EmptyProfileProps {
  username: string;
  canAddNewProfile: boolean;
  onRequestAddNewProfile: () => void;
}

export const EmptyProfile = React.memo<EmptyProfileProps>(
  ({ username, canAddNewProfile, onRequestAddNewProfile }) => {
    const text = useText(state => state.user.emptyState.noProfiles);

    return (
      <div className={css.container}>
        <Text.H2 className={css.text}>
          {canAddNewProfile ? text.descriptionPersonal : text.description(username)}
        </Text.H2>

        {canAddNewProfile && (
          <div className={css.buttonContainer}>
            <Button color="green" onClick={onRequestAddNewProfile}>
              {text.action}
            </Button>
          </div>
        )}
      </div>
    );
  },
);
