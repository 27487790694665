import React from 'react';

import cx from 'classnames';

import Link from 'next/link';

import { api, useMe } from 'api';

import { Text } from 'components/Text';
import { ButtonFollowUser } from 'components/UserCard';
import { useText } from 'text';

import { links } from 'utils/links';

import css from './UserDetails.module.css';
import { UserSocial } from './UserSocial';

interface UserDetailsProps {
  user: api.Maybe<api.User>;
  canEditCalendar: boolean;
}

export const UserDetails = React.memo<UserDetailsProps>(({ user }) => {
  const me = useMe();
  const text = useText(state => state.user);
  if (!user) {
    return null;
  }

  return (
    <section className={css.container}>
      {user.description ? (
        <Text.Regular className={css.descriptionText}>
          {user.description}
        </Text.Regular>
      ) : null}
      <div className={css.followingInfo}>
        <Link
          className={css.followersLink}
          href={links.user.followers(user.username)}
        >
          <Text.R3 className={css.followingInfoText}>
            {text.navigation.followers(user.followersCount || 0)}
          </Text.R3>
        </Link>
        <Link
          className={css.followersLink}
          href={links.user.following(user.username)}
        >
          <Text.R3 className={css.followingInfoText}>
            {text.navigation.following(user.followingCount || 0)}
          </Text.R3>
        </Link>
      </div>
      <UserSocial user={user} />
      {user.id !== me?.id && (
        <div
          className={cx(css.profileButtonsWrapper, {
            [css.profileButtonsWrapperWithShop]: user.shopUrl,
          })}
        >
          <ButtonFollowUser
            profile
            withText
            playerEventCategory="profileBlock"
            user={user}
          />
        </div>
      )}
    </section>
  );
});
