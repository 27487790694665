import React, { useMemo } from 'react';

import { GameProfileCardList } from 'components/[Game]ProfileCard';
import { useModal } from 'components/ContextModal';
import { ErrorPage } from 'components/Error';
import { Layout } from 'components/Layout';
import { EmptyProfile } from 'components/MobileProfile/EmptyProfile';
import { LayoutUserProfile } from 'layouts/LayoutUserProfile';
import { useText } from 'text';
import { dynamic } from 'utils/dynamic';

import { SharedProps } from './Container';

import css from './PageUserGames.module.css';

const SlideoverSettings = dynamic(() =>
  import('components/SlideoverSettings').then(m => m.SlideoverSettings),
);

export const Desktop: React.FC<SharedProps> = ({
  me,
  user,
  game,
  loading,
  hasRenderedOnServer,
}) => {
  const modal = useModal();
  const text = useText(state => state.controls.navigation);

  const isMe = useMemo(() => Boolean(me && me?.id === user?.id), [me, user]);

  const renderContent = () => {
    if (loading) {
      return <Layout.Loading />;
    }

    if (user) {
      return (
        <>
          <div className={css.lfgCardListWrapper}>
            <GameProfileCardList
              extended
              hideUser
              error={undefined}
              isLoading={loading}
              renderEmpty={
                <EmptyProfile
                  canAddNewProfile={isMe}
                  username={user.username}
                  onRequestAddNewProfile={() =>
                    modal.open(SlideoverSettings.Component, {})
                  }
                />
              }
              users={{ data: [user], count: 1 }}
            />
            {/* <ProfileCardList isProfileCard isMe={me?.id === user?.id} user={user} /> */}
          </div>
        </>
      );
    }

    return <ErrorPage />;
  };

  return (
    <LayoutUserProfile
      hasRenderedOnServer={hasRenderedOnServer}
      linkGame={game}
      me={me}
      title={text.profile}
      user={user}
    >
      {renderContent()}
    </LayoutUserProfile>
  );
};
