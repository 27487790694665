import React from 'react';

import { api } from 'api';

import { Icon } from '../Icons';
import { useText } from '../Language';

import { Text } from '../Text';

import { UserLink } from './UserLink';

import css from './UserLink.module.css';

interface UserLinksProps {
  links: api.Maybe<api.UserLink[]>;
  withDescriptions?: boolean;

  canAddLinks?: boolean;
  onLinkClick?: (link: api.UserLink) => Promise<void> | void;
  onRequestAdd?: () => Promise<void> | void;
  onRequestDelete?: (link: api.UserLink) => Promise<unknown> | unknown;
}

export const UserLinks = React.memo<UserLinksProps>(
  ({
    links = [],
    canAddLinks,
    withDescriptions = true,
    onLinkClick = () => undefined,
    onRequestAdd = () => undefined,
    onRequestDelete = () => undefined,
  }) => {
    const text = useText(state => state.userLinks);

    if (links.length === 0) {
      if (canAddLinks) {
        return (
          <>
            {canAddLinks ? (
              <div className={css.addLinkContainer} onClick={onRequestAdd}>
                <div className={css.addLinkIconContainer}>
                  <Icon.Plus className={css.addLinkIcon} />
                </div>
                <Text.Regular semibold size={2}>
                  {text.addLinkAction}
                </Text.Regular>
              </div>
            ) : null}

            <Text.Subtitle className={css.noDataAction} onClick={onRequestAdd}>
              {text.addFirstAction}
            </Text.Subtitle>
          </>
        );
      }

      return (
        <Text.Subtitle className={css.noDataText}>{text.errorNoLinks}</Text.Subtitle>
      );
    }

    return (
      <>
        {canAddLinks ? (
          <div className={css.addLinkContainer} onClick={onRequestAdd}>
            <div className={css.addLinkIconContainer}>
              <Icon.Plus className={css.addLinkIcon} />
            </div>
            <Text.Regular semibold size={2}>
              {text.addLinkAction}
            </Text.Regular>
          </div>
        ) : null}
        <div className={css.list}>
          {links.map(link => (
            <UserLink
              key={link.id}
              canDelete={canAddLinks}
              link={link}
              withDescription={withDescriptions}
              onClick={() => onLinkClick(link)}
              onRequestDelete={() => onRequestDelete(link)}
            />
          ))}
        </div>
      </>
    );
  },
);
