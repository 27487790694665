import React from 'react';

import { analytics } from 'analytics';
import { api } from 'api';

import { Icon } from 'components/Icons';

import { SocialLinkIcon } from 'components/SocialLink/SocialLink';

import css from './UserDetails.module.css';

interface UserMobileSocialProps {
  user: api.User;

  iconClassName?: string;
}

export const UserSocial = React.memo<UserMobileSocialProps>(
  ({ user, iconClassName }) => {
    if (!user.twitch && !user.battlenet && !user.discord && !user.twitter) {
      return null;
    }

    return (
      <div className={css.userSocial}>
        {user.twitch ? (
          <SocialLinkIcon
            className={iconClassName}
            Icon={Icon.TwitchOutlined}
            iconClassName={iconClassName}
            link={`https://twitch.tv/${user.twitch.login}`}
            value={user.twitch.username}
            onClick={() => analytics.clickTwitchCopylink(user?.id, user.username)}
          />
        ) : null}
        {user.discord ? (
          <SocialLinkIcon
            className={iconClassName}
            Icon={Icon.DiscordOutlined}
            iconClassName={iconClassName}
            value={user.discord.username}
            onClick={() => analytics.clickDiscordCopylink(user?.id, user.username)}
          />
        ) : null}

        {user.twitter ? (
          <SocialLinkIcon
            className={iconClassName}
            Icon={Icon.TwitterOutlined}
            iconClassName={iconClassName}
            value={user.twitter.username}
            onClick={() => analytics.clickBattlenetCopylink(user?.id, user.username)}
          />
        ) : null}
        {user.battlenet ? (
          <SocialLinkIcon
            className={iconClassName}
            Icon={Icon.BattlenetOutlined}
            iconClassName={iconClassName}
            value={user.battlenet.battletag}
            onClick={() => analytics.clickBattlenetCopylink(user?.id, user.username)}
          />
        ) : null}
      </div>
    );
  },
);
