import React, { useMemo } from 'react';

import { api } from 'api';

import { Icon } from '../Icons';
import { getFaviconUrl } from '../SlideoverSettings/SlideLinks/UserLinkForm';
import { Text } from '../Text';

import css from './UserLink.module.css';

interface UserLinkProps {
  link: api.UserLink;
  withDescription?: boolean;
  canDelete?: boolean;
  onClick?: () => void;
  onRequestDelete?: () => unknown | Promise<unknown>;
}

export const UserLink = React.memo<UserLinkProps>(
  ({
    link,
    withDescription = true,
    canDelete = false,
    onRequestDelete = () => undefined,
    onClick = () => undefined,
  }) => {
    const imgUrl = useMemo(() => getFaviconUrl(link.url || 'lf.group'), [link.url]);

    return (
      <div className={css.container}>
        <a
          className={css.wrapper}
          href={link.url}
          rel="nofollow noopener noreferrer"
          target="_blank"
          onClick={onClick}
        >
          <img className={css.iconImg} src={imgUrl} />
          <Text.Regular semibold size={2}>
            {link.title || ''}
          </Text.Regular>
          {canDelete ? (
            <Icon.Trash
              className={css.deleteIcon}
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onRequestDelete();
              }}
            />
          ) : null}
        </a>
        {link.description && withDescription ? (
          <Text.Regular className={css.descText}>{link.description}</Text.Regular>
        ) : null}
      </div>
    );
  },
);

export const UserMobileLink = React.memo<UserLinkProps>(({ link }) => {
  const imgUrl = useMemo(() => getFaviconUrl(link.url || 'lf.group'), [link.url]);

  return (
    <div className={css.mobileContainer}>
      <a
        className={css.wrapper}
        href={link.url}
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        <img className={css.iconImg} src={imgUrl} />
      </a>
      <div className={css.linkInfo}>
        <a href={link.url} rel="nofollow noopener noreferrer" target="_blank">
          <Text.Regular semibold size={1}>
            {link.title || ''}
          </Text.Regular>
        </a>
        {link.description ? (
          <Text.Regular className={css.descText}>{link.description}</Text.Regular>
        ) : null}
      </div>
    </div>
  );
});
