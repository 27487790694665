import React, { useCallback, useMemo, useState } from 'react';

import router from 'next/router';

import { analytics } from 'analytics';
import { api, Iterator, optimistic } from 'api';
import { SupportedGameId } from 'api/types';
import { GameProfileCardList } from 'components/[Game]ProfileCard';
import { Button } from 'components/Button';
import { PostEventList } from 'components/CalendarEventCard';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Error } from 'components/Error';
import { useTranslations } from 'components/Language';
import { LoaderBouncing } from 'components/Loaders';
import { ShopBanner } from 'components/ShopBanner';
import { Text } from 'components/Text';
import { useToasts } from 'components/Toast';
import { UserLinks } from 'components/UserLinks';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';
import { EmptyHistory } from 'containers/PageUserHistory/EmptyHistory';
import { LayoutMobileUserProfile } from 'layouts/LayoutMobileUserProfile';

import { useText } from 'text';
import { useGroupListUpdates } from 'utils/apollo/subscriptions';

import { links } from 'utils/links';
import { unique } from 'utils/main';

import { EmptyProfile } from './EmptyProfile';

import css from './MobileProfile.module.css';

interface MobileProfileProps {
  me: api.Maybe<api.User>;
  user: api.Maybe<api.User>;
  loading: boolean;
}

export const MobileProfile: React.FC<MobileProfileProps> = ({
  me,
  user,
  loading,
}) => {
  const onboardingModal = useOnboardingModal();
  const { catchToToast } = useToasts();
  const translations = useTranslations();
  const text = useText(state => state.userGames);
  const eventText = useText(state => state.event);
  const textControls = useText(state => state.controls);

  const [groups, setGroups] = useState<Iterator<api.Event[]> | undefined>();

  const initialOptions = { limit: 20, offset: 0 };
  const [options, setOptions] = useState(initialOptions);

  const [deleteLink] = api.useDeleteLinkMutation({
    notifyOnNetworkStatusChange: true,
  });

  const from = new Date();
  from.setHours(0, 0, 0, 0);

  const isMyProfile = useMemo(() => {
    return Boolean(me?.id && me?.id === user?.id);
  }, [me?.id, user?.id]);

  const userGameId = useMemo(() => {
    if (!user?.lfg?.length) {
      return undefined;
    }

    if (user.lfg.find(lfg => lfg.gameId === api.GameId.PubgMobile)) {
      return api.GameId.PubgMobile;
    }

    if (user.lfg.length === 1) {
      return user.lfg[0].gameId;
    }

    return undefined;
  }, [user?.lfg?.length]);

  const handleEditLfg = async (lfgGameId: api.GameId): Promise<void> => {
    if (!isMyProfile) {
      return;
    }

    onboardingModal.open({
      canBeClosed: true,
      slides: [Slide.GameSettings],
      initialGame: lfgGameId as SupportedGameId,
      isEditing: true,
    });
  };

  const onRequestDeleteLink = useCallback(
    (linkId: string) => {
      if (!me) {
        return;
      }

      deleteLink({
        variables: { id: linkId },
        optimisticResponse: optimistic.deleteLink(me, linkId),
      });
    },
    [me?.id],
  );

  const onRequestAddNewProfile = useCallback(() => {
    onboardingModal.open({
      canBeClosed: true,
      slides: [Slide.GameBlocks, Slide.GameSettings],
    });
  }, [me?.id]);

  const {
    fetchMore,
    client,
    loading: groupsLoading,
  } = api.useGetUserEventsQuery({
    variables: { username: user?.username || '', options: initialOptions },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      const result = data?.getUserEvents as unknown as Iterator<api.Event[]>;
      if (options.offset === 0) {
        return setGroups(result);
      }

      if (result && groups) {
        return setGroups({
          ...result,
          data: unique([...groups.data, ...result.data], group => group.id),
        });
      }
    },
  });

  useGroupListUpdates(groups?.data, client, [me]);

  const handleRequestMore = async () => {
    const offset = (options.offset || 0) + options.limit;
    const newOptions = { ...options, offset };

    setOptions(newOptions);
    await fetchMore({ variables: { options: newOptions } });
  };

  const flexGrow = useMemo(() => {
    if (user?.lfg?.length && user?.lfg?.length > 0) {
      return 1;
    }

    return 0;
  }, [user?.lfg?.length]);

  if (!user) {
    return (
      <main className={css.mobilePage}>
        <Error className={css.error}>User not Found</Error>
      </main>
    );
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className={css.loading}>
          <LoaderBouncing />
        </div>
      );
    }

    return (
      <div className={css.profileCardListWrapper} style={{ flexGrow }}>
        <ShopBanner gameId={userGameId} me={me} user={user} />
        <div className={css.myGamesTitleWrapper}>
          <Text.R5Memo semibold className={css.sectionTitle}>
            {text.sectionTitles.myLinks}
          </Text.R5Memo>
          {isMyProfile && (
            <Button
              className={css.addGamesButton}
              color="secondary"
              onClick={() => {
                router.push(links.user.links(user?.username || ''));
              }}
            >
              {textControls.actions.addLinks}
            </Button>
          )}
        </div>
        <UserLinks
          canAddLinks={false}
          links={user?.links}
          onLinkClick={() => {
            if (!user) {
              return;
            }
            analytics.clickLink(user.id, user.username);
          }}
          // onRequestAdd={() => {
          //   analytics.clickAddLink('profilePage');

          //   settings.open({ initialSlide: Slide.Links });
          // }}
          onRequestDelete={link => {
            analytics.clickDeleteLink('profilePage');

            onRequestDeleteLink(link.id);
          }}
        />
        <div className={css.myGamesTitleWrapper}>
          <Text.R5Memo semibold className={css.sectionTitle}>
            {text.sectionTitles.myGames}
          </Text.R5Memo>
          {isMyProfile && (
            <Button
              className={css.addGamesButton}
              color="secondary"
              onClick={() => {
                onboardingModal.open({
                  canBeClosed: true,
                  slides: [Slide.GameBlocks, Slide.GameSettings],
                });
              }}
            >
              {textControls.actions.addGames}
            </Button>
          )}
        </div>
        <GameProfileCardList
          extended
          hideUser
          isMobile
          error={undefined}
          isLoading={loading}
          renderEmpty={
            <EmptyProfile
              canAddNewProfile={isMyProfile}
              username={user?.username || 'User'}
              onRequestAddNewProfile={onRequestAddNewProfile}
            />
          }
          users={{ data: [user], count: 1 }}
          onRequestEditLfg={handleEditLfg}
        />
        <Text.R5Memo semibold className={css.sectionTitle}>
          {text.sectionTitles.events}
        </Text.R5Memo>
        <PostEventList
          _analyticGroup="historyTab"
          events={groups}
          isLoading={groupsLoading}
          renderEmpty={() => (
            <EmptyHistory
              username={user?.username || 'User'}
              withHomeButton={Boolean(me?.id && user?.id === me.id)}
            />
          )}
          onRequestMore={() => catchToToast(handleRequestMore())}
        />
      </div>
    );
  };

  return (
    <LayoutMobileUserProfile me={me} user={user}>
      {renderContent()}
    </LayoutMobileUserProfile>
  );
};
