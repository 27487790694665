import React from 'react';

import { api, useMe } from 'api';

import { useApp, withApp } from 'components/ContextApp';
import { ErrorPage } from 'components/Error';
import { AppLayout, Layout } from 'components/Layout';
import { PageUserGames } from 'containers/PageUserGames';
import { LayoutMobile } from 'layouts/LayoutMobile';
import { NextPage } from 'utils/next';

interface PageProfileProps {
  username: string;
  gameId: string;
}

const QUERY_GAME_ID = 'game_id';
const QUERY_ALIAS_KEY = 'username';

const Page: NextPage<PageProfileProps> = ({
  username,
  gameId,
  hydratedFetchPolicy,
  hasRenderedOnServer,
}) => {
  const me = useMe();
  const { isMobile } = useApp();

  const { data, loading, error } = api.useGetUserByUsernameQuery({
    variables: {
      username: username,
    },
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
    fetchPolicy: hydratedFetchPolicy,
  });

  if (error) {
    console.error(error);
    const err = new Error(error?.message || 'Loading on server');
    (err as any).code = 'ENOENT';

    throw err;
  }

  if (loading && !data) {
    if (isMobile) {
      return (
        <LayoutMobile me={me}>
          <Layout.Loading />
        </LayoutMobile>
      );
    }

    return (
      <AppLayout>
        <Layout.Wrapper>
          <Layout.Content routeName="" title="">
            <Layout.Loading />
          </Layout.Content>
          <Layout.Right />
        </Layout.Wrapper>
      </AppLayout>
    );
  }

  const user = data?.getUserByUsername as api.User | undefined;

  return (
    <PageUserGames
      gameId={gameId}
      hasRenderedOnServer={hasRenderedOnServer}
      loading={loading}
      user={user}
    />
  );
};

Page.getInitialProps = async ctx => {
  const gameId = ctx.query[QUERY_GAME_ID] as string;
  const username = ctx.query[QUERY_ALIAS_KEY] as string;

  return { gameId, username };
};

export default withApp(Page, { withApollo: true });
