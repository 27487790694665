import React, { useMemo } from 'react';

import cx from 'classnames';

import Link from 'next/link';

import { api } from 'api';

import { Button } from 'components/Button';
import { LayoutMobile, LayoutMobileProps } from 'layouts/LayoutMobile';
import { useText } from 'text';
import { links } from 'utils/links';

import css from './LayoutMobileUserProfile.module.css';
import { MobileUserThumbnail } from './MobileUserThumbnail';
import { UserDetails } from './UserDetails';

interface LayoutMobileUserProfileProps extends LayoutMobileProps {
  user: api.Maybe<api.User>;
}

export const LayoutMobileUserProfile: React.FC<LayoutMobileUserProfileProps> = ({
  me,
  user,
  children,
}) => {
  const textControls = useText(state => state.controls);
  const isMe = useMemo(() => Boolean(me && me?.id === user?.id), [me, user]);

  const renderContent = (): React.ReactNode => {
    if (!user) {
      return null;
    }

    return (
      <div className={css.layoutMobileUserProfile}>
        {me && isMe && (
          <Link href={links.user.settings(me?.username)}>
            <Button className={css.settingsButton}>
              {textControls.actions.editProfile}
            </Button>
          </Link>
        )}
        <div className={css.headerBackground}>
          <div
            className={cx(css.headerThemeImageWrapper, {
              [css.headerThemeDefaultImageWrapper]: !user.backgroundUrl,
            })}
            style={{
              backgroundImage: user.backgroundUrl
                ? `url("${user.backgroundUrl}")`
                : `url('https://storage.googleapis.com/lfgcdn/assets/logo-2@2x.png')`,
            }}
          >
            <div className={css.headerThemeImage} />
          </div>
        </div>

        <MobileUserThumbnail user={user} />
        <UserDetails canEditCalendar={false} user={user} />
        {children}
      </div>
    );
  };

  return <LayoutMobile me={me}>{renderContent()}</LayoutMobile>;
};

//  MERGE CONFLICT
// className={css.headerThemeImageWrapper}
// style={
//   user.backgroundUrl
//     ? {
//         backgroundImage: `url("${user.backgroundUrl}")`,
//         backgroundSize: 'cover !important',
//         backgroundBlendMode: 'unset !important',
//       }
//     : {}
// }
