import React from 'react';

import Link from 'next/link';

import { Button } from 'components/Button';
import { Text } from 'components/Text';
import { useText } from 'text';
import { links } from 'utils/links';

import css from './EmptyHistory.module.css';

interface EmptyHistoryProps {
  username: string;
  withHomeButton: boolean;
}

export const EmptyHistory = React.memo<EmptyHistoryProps>(
  ({ username, withHomeButton }) => {
    const text = useText(state => state.user.emptyState.noEvents);

    return (
      <div className={css.container}>
        <Text.H2 className={css.text}>
          {withHomeButton ? text.descriptionPersonal : text.description(username)}
        </Text.H2>

        {withHomeButton && (
          <div className={css.buttonContainer}>
            <Link href={links.home}>
              <Button color="green">{text.action}</Button>
            </Link>
          </div>
        )}
      </div>
    );
  },
);
